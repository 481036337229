
import {
    CardElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { addPaymentInfo, purchase } from "../utils/meta/metaPixelEvent";


const StripeForm = (props) => {
    const [email, setEmail] = useState("");
    const [oneoff, setOneoff] = useState(false);
    const [amount, setAmount] = useState("");
    const [userID, setUserId] = useState("");
    const [name, setName] = useState("");
    const [entries, setEntries] = useState("");

    const [drawContentData, setDrawContentData] = useState({});
    const [hasReachedTicketCap, setHasReachedTicketCap] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const siteId = process.env.REACT_APP_SITE_ID;
    const APIURL = process.env.REACT_APP_API_URL;
  
    useEffect(() => {
        console.log('props====>' , props)
        setEmail(props.email);
        setAmount(props.amount);
        setUserId(props.UserID);
        setName(props.name);
        setOneoff(props.oneoff);
        setEntries(props.entries);
        setIsModalOpen(props.oneShow);
    }, [props]);
    // useEffect(() => {
    //     console.log("imadohdajsdbajsdna,s=", isModalOpen)
    //     if (isModalOpen) {
    //         console.log('inside if=======')
    //     axios.get(APIURL + '/sites/' + siteId + '/draws').then(async (drawDetails) => {
    //         setDrawContentData(drawDetails);
    //         console.log('inside thththththththth')
    //         const totalPurchasedTickets = await axios.get(APIURL + `/tickets/getTicketsByDrawID/${drawDetails.data.content[0].drawID}`, {
    //             withCredentials: true,
    //             credentials: "same-origin"
    //           });
    //         console.log('inside 232322323', totalPurchasedTickets);

    //           const maximiumTickets = JSON.parse(drawDetails.data.content[0].drawContent).maxTickets;
    //           console.log('totalPurchasedTickets', totalPurchasedTickets.data.content.length);
    //           console.log('maximiumTickets', maximiumTickets);
    //           if(totalPurchasedTickets.data.content.length >= maximiumTickets){
    //               toast.error("Sorry, this draw is sold out.");
    //               setHasReachedTicketCap(true);
    //                 // props.setOneShow(false);
    //                 props.setAreTicketsSoldOut(true);
    //             } 
    //     });
    //     }
    // },[isModalOpen]);

    useEffect(() => {
        console.log("Modal Open State:", isModalOpen);
        
        const fetchData = async () => {
            if (!isModalOpen) return;
    
            try {
                console.log('Fetching draw details...');
                const drawDetailsResponse = await axios.get(`${APIURL}/sites/${siteId}/draws`, {
                    withCredentials: true,
                    credentials: "same-origin"
                });
                setDrawContentData(drawDetailsResponse);
                
                const drawID = drawDetailsResponse.data.content[0].drawID;
                const totalPurchasedTicketsResponse = await axios.get(`${APIURL}/tickets/getTicketsByDrawID/${drawID}`, {
                    withCredentials: true,
                    credentials: "same-origin"
                });
                
                const maximumTickets = JSON.parse(drawDetailsResponse.data.content[0].drawContent).maxTickets;
                console.log('Total Purchased Tickets:', totalPurchasedTicketsResponse.data.content.length);
                console.log('Maximum Tickets:', maximumTickets);
    
                if (totalPurchasedTicketsResponse.data.content.length >= maximumTickets) {
                    toast.error("Sorry, this draw is sold out.");
                    setHasReachedTicketCap(true);
                    props.setOneShow(false);
                    props.setAreTicketsSoldOut(true);
                }
    
            } catch (error) {
                console.error('Error in API call:', error);
                toast.error("Error fetching draw details.");
            }
        };
    
        fetchData();
    }, [isModalOpen]);
    
    const handleSubmit = (stripe) => async () => {
        // await createPaymentAndCheckTickets();
        try {
           
            const cardElement = elements.getElement(CardElement);
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            if (error) {
                console.log('[error While Creating Element]', error);
            } else {
                props.hideModal();
                addPaymentInfo();
                axios.get(APIURL + `/tickets/getTicketsByDrawID/${drawContentData.data.content[0].drawID}`, {
                    withCredentials: true,
                    credentials: "same-origin"
                }).then(async totalPurchasedTickets => {
                  const maximiumTickets = JSON.parse(drawContentData.data.content[0].drawContent).maxTickets;
                  console.log('totalPurchasedTickets', totalPurchasedTickets.data.content.length);
                  console.log('maximiumTickets', maximiumTickets);
                  if(totalPurchasedTickets.data.content.length >= maximiumTickets){
                      toast.error("Oops! This draw is sold out.");
                      setHasReachedTicketCap(true);
                      props.setAreTicketsSoldOut(true);
                      props.setOneShow(false);
                      throw new Error("Oops! This draw is sold out.");
                    } 
                })
                .then(async () => {
                axios.get(APIURL + '/sites/' + siteId + '/draws').then(async DrawData => {
                    const res = await toast.promise(axios.post(APIURL + `/transactions/stripe3ds/checkout`, {
                        amount: amount,
                        userEmail: email,
                        name: name?.trim() !== '' ? name : "Guest Checkout",
                        address1: '-',
                        address2: '-',
                        postcode: '-',
                        stripeToken: paymentMethod.id,
                        "transactionItems": [
                            {
                                "drawID": DrawData.data.content[0].drawID,
                                "drawCauseID": DrawData.data.content[0].drawHostCauseID,
                                "ticketPrice": amount,
                                "ticketQuantity": entries,
                                "itemTotal": amount,
                                "bundleTotal": amount,
                                "isSubscription": oneoff ? 0 : 1,
                            }
                        ],
                        transactionUserID: userID,
                        "transactionSiteID": siteId,
                        "product": [
                            {
                                "name": DrawData.data.content[0].drawName,
                                "price": amount,
                                "description": DrawData.data.content[0].drawName + " Subscription",
                                "metadata": {
                                    "email": email
                                },
                                "data": [
                                    {
                                        "id": 6,
                                        "name": DrawData.data.content[0].drawName,
                                        "drawID": DrawData.data.content[0].drawID,
                                        "drawCauseID": DrawData.data.content[0].drawHostCauseID,
                                        "price": amount,
                                        "quantity": entries,
                                        "slug": DrawData.data.content[0].drawPageURL,
                                        "subscription": !oneoff,
                                        "subscriptionPeriod": "month",
                                        "itemTotal": amount,
                                    }
                                ]
                            }
                        ],
                    }, {
                        withCredentials: true,
                        credentials: "same-origin"
                    }).catch((e)=>{
                        if(e.code === "ERR_BAD_REQUEST")
                        console.log('Something went wrong',e);
                        toast.error(e.response.data.message); 
                    }),{
                        pending: 'Please wait...',
                        error: 'Som ething went wrong'
                    });
                    let transactionID = res.data.transactionID;

                    if (res.data.actionRequired) {
                        // We perform 3D Secure authentication
                        const { paymentIntent, error } = await toast.promise(stripe.confirmCardPayment(
                            res.data.clientSecret
                        ), {
                            pending: 'Please wait...',
                            error: '3D Secure Failed'
                        });
                        if (error) {
                            await axios.post(APIURL + `/transactions/stripe3ds/updateStatus`, {
                                transactionProcessorID: error.payment_intent.id,
                                status: 4
                            });
                            // console.log("3ds",error);
                            toast("3d Secure Failed.", { type: "error" });
    
                        };
                        if (paymentIntent.status === "succeeded")
                            console.log(paymentIntent);
                            try {
                                const res2 = await axios.post(APIURL + `/transactions/stripe3ds/updateStatus`, {
                                    transactionProcessorID: paymentIntent?.id,
                                    sendMail: true,
                                    mailData: res.data.mailData,
                                    status: 3
                                });
                                toast("Payment Done Successfully", { type: "success" });
                                const currency = 'GBP';
                                const value = amount;
                                purchase(currency, value);
                                await props.timeout(2000);
                                console.log(`/payment-success/${transactionID.toString()}`);
                                props.navigate(`/payment-success/${transactionID.toString()}`);
                            } catch (e) {
                                console.log("3dsError Not Subs",e);
                            }finally{
                                toast("Payment Done Successfully", { type: "success" });
                                const currency = 'GBP';
                                const value = amount;
                                purchase(currency, value);
                                await props.timeout(2000);
                                console.log(`/payment-success/${transactionID.toString()}`);
                                props.navigate(`/payment-success/${transactionID.toString()}`);
                            }
                    } else {
                        toast("Payment Done Successfully", { type: "success" });
                        const currency = 'GBP';
                        const value = amount;
                        purchase(currency, value);
                        await props.timeout(2000);
                        props.navigate(`/payment-success/${transactionID.toString()}`);
                    }
                });
                }).catch((error)=>{
                    console.error(error);
                    return toast("Payment failed!", { type: "error" });
                });

            }
        } catch (error) {
            console.error(error);
            return toast("Payment failed!", { type: "error" });
        }
    };


    async function createPaymentAndCheckTickets() {
        try {
            const cardElement = elements.getElement(CardElement);
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
    
            if (error) {
                console.log('[Error While Creating Element]', error);
                return;
            }
    
            props.hideModal();
            addPaymentInfo();
    
            const totalPurchasedTicketsResponse = await axios.get(`${APIURL}/tickets/getTicketsByDrawID/${drawContentData.data.content[0].drawID}`, {
                withCredentials: true,
                credentials: "same-origin"
            });
    
            const maximumTickets = JSON.parse(drawContentData.data.content[0].drawContent).maxTickets;
    
            if (totalPurchasedTicketsResponse.data.content.length >= maximumTickets) {
                toast.error("Oops! This draw is sold out.");
                setHasReachedTicketCap(true);
                props.setAreTicketsSoldOut(true);
                props.setOneShow(false);
                return;
            }
            console.log('Payment Method:', paymentMethod);
            await processTransaction(paymentMethod);
        } catch (error) {
            console.error(error);
            toast("Payment failed!", { type: "error" });
        }
    }
    
    async function processTransaction(paymentMethod) {
        try {
            const DrawData = await axios.get(`${APIURL}/sites/${siteId}/draws`);
            console.log('drawData', DrawData);
            const res = await toast.promise(
                axios.post(`${APIURL}/transactions/stripe3ds/checkout`, {
                    amount: amount,
                        userEmail: email,
                        name: name?.trim() !== '' ? name : "Guest Checkout",
                        address1: '-',
                        address2: '-',
                        postcode: '-',
                        stripeToken: paymentMethod.id,
                        "transactionItems": [
                            {
                                "drawID": DrawData.data.content[0].drawID,
                                "drawCauseID": DrawData.data.content[0].drawHostCauseID,
                                "ticketPrice": amount,
                                "ticketQuantity": entries,
                                "itemTotal": amount,
                                "bundleTotal": amount,
                                "isSubscription": oneoff ? 0 : 1,
                            }
                        ],
                        transactionUserID: userID,
                        "transactionSiteID": siteId,
                        "product": [
                            {
                                "name": DrawData.data.content[0].drawName,
                                "price": amount,
                                "description": DrawData.data.content[0].drawName + " Subscription",
                                "metadata": {
                                    "email": email
                                },
                                "data": [
                                    {
                                        "id": 6,
                                        "name": DrawData.data.content[0].drawName,
                                        "drawID": DrawData.data.content[0].drawID,
                                        "drawCauseID": DrawData.data.content[0].drawHostCauseID,
                                        "price": amount,
                                        "quantity": entries,
                                        "slug": DrawData.data.content[0].drawPageURL,
                                        "subscription": !oneoff,
                                        "subscriptionPeriod": "month",
                                        "itemTotal": amount,
                                    }
                                ]
                            }
                        ],
                }, {
                    withCredentials: true,
                    credentials: "same-origin"
                }), {
                    pending: 'Please wait...',
                    error: 'Something went wrong'
                }
            );
    
            let transactionID = res.data.transactionID;
                    if (res.data.actionRequired) {
                        // We perform 3D Secure authentication
                        const { paymentIntent, error } = await toast.promise(stripe.confirmCardPayment(
                            res.data.clientSecret
                        ), {
                            pending: 'Please wait...',
                            error: '3D Secure Failed'
                        });
                        if (error) {
                            await axios.post(APIURL + `/transactions/stripe3ds/updateStatus`, {
                                transactionProcessorID: error.payment_intent.id,
                                status: 4
                            });
                            // console.log("3ds",error);
                            toast("3d Secure Failed.", { type: "error" });
    
                        };
                        if (paymentIntent.status === "succeeded")
                            console.log(paymentIntent);
                            try {
                                const res2 = await axios.post(APIURL + `/transactions/stripe3ds/updateStatus`, {
                                    transactionProcessorID: paymentIntent?.id,
                                    sendMail: true,
                                    mailData: res.data.mailData,
                                    status: 3
                                });
                                toast("Payment Done Successfully", { type: "success" });
                                const currency = 'GBP';
                                const value = amount;
                                purchase(currency, value);
                                await props.timeout(2000);
                                console.log(`/payment-success/${transactionID.toString()}`);
                                props.navigate(`/payment-success/${transactionID.toString()}`);
                            } catch (e) {
                                console.log("3dsError Not Subs",e);
                            }finally{
                                toast("Payment Done Successfully", { type: "success" });
                                const currency = 'GBP';
                                const value = amount;
                                purchase(currency, value);
                                await props.timeout(2000);
                                console.log(`/payment-success/${transactionID.toString()}`);
                                props.navigate(`/payment-success/${transactionID.toString()}`);
                            }
                    } else {
                        toast("Payment Done Successfully", { type: "success" });
                        const currency = 'GBP';
                        const value = amount;
                        purchase(currency, value);
                        await props.timeout(2000);
                        props.navigate(`/payment-success/${transactionID.toString()}`);
                    }
        } catch (error) {
            console.error('Transaction Error:', error);
            toast.error(error.response?.data.message || "Payment failed!", { type: "error" });
        }
    }
    

    const stripe = useStripe();
    const elements = useElements();

    return (
        <>
            {stripe && stripe._keyMode === 'live' ? '' :
                <div className="d-flex justify-content-center mb-2">
                    <span className="text-white text-center bg-danger px-3 py-1" style={{ marginTop: '-25px' }}>Stripe : {stripe?._keyMode} Mode</span>
                </div>
            }
            <h3 className="branding1 text-center">Play Fund Win</h3>
            <span className="text-muted text-center d-flex justify-content-center mt-3">{props.email}</span>
            <div className="p-3 my-3 border rounded-4" >
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: "16px",
                                color: "#424770",
                                "::placeholder": {
                                    color: "#aab7c4",
                                },
                            },
                            invalid: {
                                color: "#9e2146",
                            },
                        },
                    }}
                />

            </div>
            <button disabled={hasReachedTicketCap} className="bg-branding-1 text-light border-0 shadow rounded-4 px-3 w-100 py-3" onClick={handleSubmit(stripe, elements)}>Pay Now <strong> £{amount} </strong></button>
            <span className="text-muted text-center d-flex justify-content-center mt-3">* 100% Secure & Safe Payment *</span>
        </>
    );
}


export default StripeForm;