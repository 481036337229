import React from "react";
import { Link } from 'react-router-dom'
import Facebook from '../images/facebook.svg'
import Twitter from '../images/twitter-x-new.svg'
import Instagram from '../images/instagram.svg'
import LogoWhite from '../images/luke-hedger-motorsport.png';
import LogoRed from '../images/luke-hedger-motorsport.png'
import LogoDark from '../images/luke-hedger-motorsport.png';
import secureLocalStorage from 'react-secure-storage'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useState,useEffect  } from 'react'
import axios from "axios";



export default function Header(){
  const [scroll, setScroll] = React.useState(false);
  const [Sidebar,setSidebar] = React.useState(false);
  const [login ,setLogin] = useState(secureLocalStorage.getItem('LogObject') ? secureLocalStorage.getItem('LogObject').loggedin : false);
  
  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  function logout(){
      secureLocalStorage.clear();
      axios.delete(APIURL + '/auth/logout');
      setLogin(false);
    }
    React.useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10);
      });
      
    }, []);
  
    return( 
    <header className={scroll ? "open site-header font-face-sh py-2 position-sticky top-0 bg-branding-1 z-index-9" : "site-header font-face-sh py-2 bg-branding-1 siteHeaderColorDark"}>
    <div className='container'>
      <div className={`inner-header row d-flex align-items-center `}>
        <div className='logo col-sm-6 col-md-6 col-lg-6 col-6'>
            <Link to={'/'}><img className='start-0 not-responsive' src={scroll ? LogoRed : LogoRed} alt="logo-white" style={{ width:270}} /></Link>
            <Link to={'/'}><img className='start-0 only-responsive d-none' src={scroll ? LogoRed : LogoWhite} alt="logo-white" style={{ width:270}} /></Link>
          {/* <Link to={'/'} >
          <img className='start-0' src={LogoDark } alt="logo-white" style={{width: 145}} />
          </Link> */}
        </div>
        <div className='desktop-menu col-md-6 d-flex justify-content-end align-items-center'>
        {login ? 
            <DropdownButton
            align="end"
            title="Account"
            id="dropdown-menu-align-end"
            className="text-white transparent"            
          >
            <Dropdown.Item eventKey="1" href="/account">My Impact</Dropdown.Item>
            <Dropdown.Item eventKey="2" href="/account/prizes">Prizes</Dropdown.Item>
            <Dropdown.Item eventKey="3" href="/account/subscription">Manage Subscription</Dropdown.Item>
            <Dropdown.Item eventKey="6" href="/account/payment-method">Manage Payment</Dropdown.Item>
            <Dropdown.Item eventKey="4" href="/account/details">Settings</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="5" onClick={()=>logout()}>Log out</Dropdown.Item>
          </DropdownButton>
            :
            <>
          <Link to={"/login"} className={`text-white text-decoration-none fs-6 font-face-sh fw-bolder text-white`}>Login</Link>
          <Link to={"/signup"} className={`text-white py-3 px-5 border border-2 rounded-pill text-decoration-none fs-6 ms-4 lh-1 font-face-sh fw-bolder text-white`}>Join Now</Link> 
          </>
        }
        </div>
        <div className='col-sm-6 col-md-6 col-6 mobile-menu justify-content-end d-flex align-items-center d-none'>
          <div className={Sidebar ? "MenuButton_rootActive":"MenuButton_root"} onClick={() => setSidebar(!Sidebar)}>
            <div className={Sidebar ? "MenuButton_firstLineActive":"MenuButton_firstLine"}></div>
            <div className={Sidebar ? "MenuButton_secondLineActive":"MenuButton_secondLine"}></div>
            <div className={Sidebar ? "MenuButton_thirdLineActive":"MenuButton_thirdLine"}></div>
          </div>
        </div>
      </div>
    </div>

    <div id="mySidebar" className={Sidebar ? "open sidebar bg-light h-100 top-0 end-0 position-fixed d-flex flex-column ease-in-out overflow-hidden py-4 px-4" : "sidebar h-100 w-0 end-0 top-0 bg-light position-fixed pt-2 ease-in-out overflow-hidden"}  >
      <div className='logo-red col-md-12'>
        <Link to={'/'}><img className='start-0' src={LogoDark} alt="logo-white" style={{width: 270}} /></Link>
      </div>
      <div className='mobile-menu-nav col-md-12 mt-5 d-flex flex-column'>
        {login ? 
        
        <div className='mobile-menu-nav col-md-12 mt-5 d-flex flex-column'>
          <Link to={"/subscription-plan"} className='text-center mb-3 py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 lh-1 font-face-sh'>Enter The Draw</Link>
        <div className='fs-6 mb-1' style={{color: '#999'}}><strong>Account</strong></div>
        <Link to={"/account"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>My Impact</Link>
        <Link to={"/account/prizes"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Prizes</Link>
        <Link to={"/account/subscription"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Manage Subscription</Link>
        <Link to={"/account/payment-method"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Manage Payment</Link>
        <Link to={"/account/details"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Settings</Link>
        <hr />
        <Link onClick={logout} to={"/"} className='black-clr text-decoration-none fs-5 branding-1-hover'>Log Out</Link>
      </div>
        : 
        <>
        <Link to={"/login"} className='black-clr text-decoration-none fs-5 font-face-sh branding-1-hover'>Login</Link>
        <Link to={"/signup"} className='py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1'>Join Now</Link>
        </>
      }
      </div>

      <div className='col-sm-12 col-md-12 col-lg-3 mt-auto'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 branding-1'>
              <p className='mt-4 pt-2 font-face-sh-bold'>Follow us</p>
              <div className='d-flex'>
                <a className="black-clr text-decoration-none" href="https://facebook.com/PlayFundWin//" target='_blank'><img className="me-3" src={Facebook} alt="Facebook" style={{width: 24}} /></a>
                <a className='black-clr text-decoration-none' href="https://twitter.com/PlayFundWin" target='_blank'><img className='me-3' src={Twitter} alt="Twitter" style={{width: 24}}/></a>
                <a className='black-clr text-decoration-none' href="https://www.instagram.com/playfundwin" target='_blank'><img className='me-3' src={Instagram} alt="Instagram" style={{width: 24}}/></a>
              </div>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-12 branding-1'>
              <p className='mt-4 pt-2' style={{fontSize: 14}}>Luke Hedger Sponsorship Draw is operated by Play Fund Win</p>
              <div className='d-flex font-face-sh-bold' style={{fontSize: 14}}>
                <Link className='branding-1 text-decoration-none pe-5 branding-1-hover' to={'/terms-conditions'}>Terms & Conditions</Link>
                <Link className='branding-1 text-decoration-none branding-1-hover' to={'/privacy-policy'}>Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
    </div>

  </header>)

} 